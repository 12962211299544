import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DEFAULT_BACKGROUND, FLIRT_BACKGROUND, FRIEND_BACKGROUND, FUN_BACKGROUND } from "../core/colors";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Menu from "./components/menu/Menu";
import { useDispatch } from 'react-redux';
import { userSignInWithTokenAction } from '../reducers/common';

function Auth() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const token = search.get('token');
    if (token) {
      dispatch(userSignInWithTokenAction({ token }));
    }
  }, [location])

  const isLoggedIn = useSelector((state: any) => state.common.isLoggedIn);
  const type = useSelector((state: any) => state.common.type);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    }
  }, [isLoggedIn])

  const getBackgroundColor = () => {

    if (type === 'flirt') {
      return FLIRT_BACKGROUND
    }

    if (type === 'fun') {
      return FUN_BACKGROUND
    }

    if (type === 'friend') {
      return FRIEND_BACKGROUND
    }

    return DEFAULT_BACKGROUND

  }

  return (
    <>
      <Header onMobileMenuOpen={() => setMobileMenuOpen(!mobileMenuOpen)}/>
      <div className="content-area" style={{
        backgroundColor: getBackgroundColor()
      }}>
        <div className="content-area-container">
          {mobileMenuOpen && (
            <>
              <div className="mobile-menu-fade" onClick={() => {
                setMobileMenuOpen(false)
              }}/>
              <Menu className={'mobile-menu'} onClick={() => {
                setMobileMenuOpen(false)
              }}/>
            </>
          )}
          <Menu className={'aside'}/>
          <div className="content-area-inner">
            <Outlet/>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Auth;
