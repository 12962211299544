import { useState } from "react";

const hideIcon = require('../../assets/icons/hide.png');
const viewIcon = require('../../assets/icons/view.png');


type FieldProps = {
  value: string,
  label?: string,
  type?: string,
  id: string,
  className: string,
  error?: string
  onChange: (value: string) => void,

}

function Field({ value, label, type = 'text', id, className, error, onChange }: FieldProps) {
  const [hide, setHide] = useState(true);
  const [localType, setLocalType] = useState(type);

  const onEyeClick = () => {
    setHide((prev) => {
      setLocalType(!prev ? 'password' : 'text');
      return !prev;
    });
  }

  return (
    <div className={`${className}-area`}>
      {label && <label htmlFor={id} className={`${className}-label`}>{label}</label>}
      <input
        type={localType}
        className={`${className}-input ${error && 'error'}`}
        style={type === 'password' ? { paddingRight: '40px' } : {}}
        id={id}
        value={value}
        onChange={(event: any) => {
          onChange(event.target.value)
        }}
      />
      {type === 'password' && (
        <div className="input-eye" onClick={onEyeClick}>
          <img src={hide ? hideIcon : viewIcon} />
        </div>
      )}
      {error && <span className="login-page-fields-error">{error}</span>}
    </div>
  );
}

export default Field;
