import { SITE_URL } from "../../../core/links";
import './Header.css';

const logo = require('../../../assets/logo.png');
const barIcon = require('../../../assets/bar.png');

function Header({ onMobileMenuOpen }: any) {
  return (
    <header className="header">
      <div className="container with-flex-row">
        <a href={'/'} className="header-logo">
          <img src={logo} alt="" />
        </a>
        <div className="header-right">
          <ul className="header-menu">
            <li><a href={SITE_URL}>About us</a></li>
            <li><a href={SITE_URL}>Blog</a></li>
            <li><a href={SITE_URL}>Contact Us</a></li>
          </ul>
        </div>
        <div className="header-mobile-right">
          <span className="header-bar" onClick={() => {
            onMobileMenuOpen()
          }}>
            <img className="header-bar-icon" src={barIcon} alt="" />
          </span>
        </div>
      </div>
    </header>
  );
}

export default Header;
