import React, { useEffect, useState } from 'react';
import './UserPage.css';
import { useNavigate, useParams } from "react-router-dom";
import { apiProfileBlock, apiProfileFavourite, apiProfileFlex, apiProfileLoad } from "../../actions/profileActions";
import { useDispatch, useSelector } from "react-redux";
import UserPageElement from './components/UserPageElement';
import { getCroppedImageUrl, getImageUrl } from "../../helpers/helpers";
import { blockAction, createChatAction, favouriteAction, flexAction, setActiveChatAction } from "../../reducers/profile";
import moment from "moment";
import UserPagePhotos from "./components/UserPagePhotos";
import Modal from "../../components/ui/modal/Modal";
// @ts-ignore
import { Lightbox } from "react-modal-image";
import { visibleAction } from "../../reducers/common";
import ShareModal from "./components/ShareModal";
import NoteModal from "./components/NoteModal";

const heyIcon = require('../../assets/hey.png');

const backIcon = require('../../assets/back.png');

const block_icon = require('../../assets/actions/icon-block.png');
const flex_icon = require('../../assets/actions/icon-flex.png');
const messages_icon = require('../../assets/actions/icon-message.png');
const note = require('../../assets/actions/icon-note.png');
const share = require('../../assets/actions/icon-share.png');
const star_icon = require('../../assets/actions/star-outline.png');

const block_icon_active = require('../../assets/actions/icon-block-active.png');
const flex_icon_active = require('../../assets/actions/icon-flex-active.png');
const messages_icon_active = require('../../assets/actions/icon-message-active.png');
const star_icon_active = require('../../assets/actions/star-outline-active.png');

const onlineGreen = require('../../assets/online/green.png');
const onlineLurking = require('../../assets/online/lurking.png');
const onlineRed = require('../../assets/online/red.png');

const locationIcon = require('../../assets/location.png');

const bluredImg = require('../../assets/bloorImage.png');

function UserPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profiles = useSelector((state: any) => state.common.profiles);
  const type = useSelector((state: any) => state.common.type);
  const token = useSelector((state: any) => state.common.token);
  const templates = useSelector((state: any) => state.common.templates)
  const [photoOpen, setPhotoOpen] = useState(false);

  const myProfile = profiles.find((profile: any) => { return profile.type === type })
  if (myProfile === undefined) {
    navigate('/profiles')
  }

  const [profile, setProfile] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [noteModalOpen, setNoteModalOpen] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const { id } = useParams();

  const flexes = useSelector((state: any) => state.profile.flexes)
  const favourites = useSelector((state: any) => state.profile.favourites)
  const blocks = useSelector((state: any) => state.profile.blocks)
  const chats = useSelector((state: any) => state.profile.chats)

  const chat = chats.find((_chat: any) => {
    return (_chat.target_profile_id == myProfile.id && _chat.src_profile_id === id) || (_chat.target_profile_id == id && _chat.src_profile_id === myProfile.id)
  })

  useEffect(() => {

    loadUserData()

  }, [id])

  const loadUserData = async () => {

    setLoading(true)
    if (id === undefined) {
      return
    }

    const profile = await apiProfileLoad(token, id.toString())
    setLoading(false)
    setProfile(profile)
    dispatch(visibleAction({
      profile_id: profile.id
    }))

  }

  const countDistance = () => {

    let feet = profile.distance * 5280;

    if (feet < 250) {
      return '<250 feet';
    }

    if (feet < 5280) {
      return parseInt(feet.toString()) + ' feet';
    }

    let miles = feet / 5280;

    if (miles < 20) {
      return miles.toFixed(1) + ' mi';
    }

    return parseInt(miles.toString()) + ' mi';

  }

  const getOnlineIcon = () => {

    if (profile.active === 0) {
      return <img src={onlineRed} className="user-page-location-circle" />
    }

    if (profile.online === 1) {
      return <img src={onlineGreen} className="user-page-location-circle" />
    }

    return <img src={onlineLurking} className="user-page-location-circle" />

  }

  const flex = () => {

    apiProfileFlex(token, profile.id);
    dispatch(flexAction({
      profile_id: profile.id
    }))

  }

  const favourite = () => {

    apiProfileFavourite(token, profile.id);
    dispatch(favouriteAction({
      profile_id: profile.id
    }))

  }

  const block = () => {

    apiProfileBlock(token, profile.id);
    dispatch(blockAction({
      profile_id: profile.id
    }))

  }

  const sendMessage = () => {

    if (chat !== undefined) {
      dispatch(setActiveChatAction({
        active_chat: chat
      }))
    } else {
      dispatch(createChatAction({
        profile: {
          id: profile.id,
          avatar: profile.avatar,
          name: profile.name,
          active: profile.active,
          online: profile.online
        },
        timestamp: moment().unix()
      }))
    }
    navigate('/messages')

  }

  const isBlured = profile?.adult && profile?.adult_status === 1;

  return (
    <div>
      <div className="profiles-page">
        <div className="container with-flex-row">
          <div className="profiles-page-inner">
            <>
              <div className="content-area-top">
                <div className="content-area-top-left" onClick={() => {
                  navigate(-1)
                }}>
                  <img src={backIcon} alt="" className="content-area-top-left-icon" />
                </div>
                <h1 className="content-area-title">{profile !== null && profile.name}</h1>
              </div>
              {loading || profile === null ? (
                <div className='user-page'>
                  <div className="spinner" />
                </div>
              ) : (
                <div className="user-page">
                  {photoOpen && (
                    <Lightbox
                      large={getImageUrl(profile.avatar)}
                      alt={profile.name}
                      onClose={() => {
                        setPhotoOpen(false)
                      }}
                    />
                  )}
                  <Modal
                    open={noteModalOpen}
                    onClose={() => {
                      setNoteModalOpen(false)
                    }}
                    title={'Note'}
                    content={(
                      <NoteModal
                        targetProfileId={profile.id}
                        onClose={() => {
                          setNoteModalOpen(false)
                        }}
                      />
                    )}
                    hasBottom={true}
                  />
                  <Modal
                    open={shareModalOpen}
                    onClose={() => {
                      setShareModalOpen(false)
                    }}
                    title={'Your other profile’s visibility to this user'}
                    content={(
                      <ShareModal targetUserId={profile.user_id} myProfile={myProfile} />
                    )}
                    hasBottom={false}
                  />
                  <div className="user-page-left">
                    {profile.user.hey === 1 && <img src={heyIcon} alt="" className="user-page-hey" />}
                    <div
                      style={{ backgroundImage: `url("${isBlured ? bluredImg : getCroppedImageUrl(profile.avatar, 276, 276)}")` }}
                      className="user-page-photo"
                      onClick={() => setPhotoOpen(true && !isBlured)}
                    />
                    <div className="user-page-bottom">
                      {getOnlineIcon()}
                      <span className="user-page-name">{profile.name}</span>
                      <span className="user-page-location">
                        <img src={locationIcon} className="user-page-location-icon" alt="" />
                        <span className="user-page-location-text">{countDistance()}</span>
                      </span>
                    </div>
                    {id && <UserPagePhotos profile_id={id} />}
                    <div className="user-page-about-text">
                      {profile.types !== null && (profile.types.find((type: any) => type.type_id === 200)) !== undefined && (
                        <>
                          <span className="user-page-block-title">About</span>
                          <span className="user-page-block-text">{profile.types.find((type: any) => type.type_id === 200).value}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="user-page-right">
                    <div className="user-page-action-icons">
                      <button className="user-page-action-icon" onClick={() => {
                        setShareModalOpen(true)
                      }}>
                        <img src={share} alt="" className="user-page-action-icon-image" />
                      </button>
                      <button className={`user-page-action-icon ${flexes.indexOf(profile.id) !== -1 && 'active'}`} onClick={flex}>
                        <img src={flexes.indexOf(profile.id) !== -1 ? flex_icon_active : flex_icon} alt="" className="user-page-action-icon-image" />
                      </button>
                      <button className={`user-page-action-icon ${chat !== undefined && 'active'}`} onClick={sendMessage}>
                        <img src={chat !== undefined ? messages_icon_active : messages_icon} alt="" className="user-page-action-icon-image" />
                      </button>
                      <button className={`user-page-action-icon ${favourites.indexOf(profile.id) !== -1 && 'active'}`} onClick={favourite}>
                        <img src={favourites.indexOf(profile.id) !== -1 ? star_icon_active : star_icon} alt="" className="user-page-action-icon-image" />
                      </button>
                      <button className="user-page-action-icon" onClick={() => {
                        setNoteModalOpen(true)
                      }}>
                        <img src={note} alt="" className="user-page-action-icon-image" />
                      </button>
                      <button className={`user-page-action-icon ${blocks.indexOf(profile.id) !== -1 && 'active'}`} onClick={block}>
                        <img src={blocks.indexOf(profile.id) !== -1 ? block_icon_active : block_icon} alt="" className="user-page-action-icon-image" />
                      </button>
                    </div>
                    {templates[profile.type] !== undefined && templates[profile.type].single.map((element: any) => {
                      if (element.id === 200 || profile === null || profile.types === null) {
                        return <div />
                      }

                      const values = profile.types.filter((type: any) => type.type_id === element.id)
                      if (values.length === 0) {
                        return <div />
                      }

                      return (
                        <UserPageElement element={element} values={values} />
                      )
                    })}
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  )

}

export default UserPage;
